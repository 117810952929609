<template>



  <div style="text-align: center;">
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Realizar firma</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: center;">
            <canvas ref="canvas" name="customer_signature" id='drawing-pad' style="border: 1px solid black;cursor:crosshair;" width='300' height='300'>
              This is an interactive drawing pad.
            </canvas>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button class="btn btn-danger" @click='resetCanvas'>Borrar firma</button>
            <button class="btn btn-primary" data-dismiss="modal" @click='saveImage'>Firmar documento</button>
          </div>
        </div>
      </div>
    </div>
    

    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
      Firmar documento
    </button>
    <button type="button" :disabled="isActive" class="btn btn-success" @click='sendData'>
      Enviar firma
    </button>
    
    <hr>
    <div style="text-align: center;">
      <img ref="img" src="" id='canvas-image'>
      <small>Esta firma se anexará al documento.</small>
      <small>{{serviceOrderId}}</small>
    </div>
    
  </div>



</template>

<script>
  export default {
    props:['serviceOrderId'],
    data(){
        return{
        canvas: null,
        context: null,
        isDrawing: false,
        startX: 0,
        startY: 0,
        points: [],
        csrfToken:'',
        isActive: true,
        }
  },
  
  mounted(){
    var vm = this;
    vm.canvas = vm.$refs.canvas
    vm.context = vm.canvas.getContext("2d");
    vm.canvas.addEventListener('mousedown', vm.mousedown);
    vm.canvas.addEventListener('mousemove', vm.mousemove);
    document.addEventListener('mouseup', vm.mouseup);
    vm.canvas.addEventListener('touchstart', vm.touchstart);
    vm.canvas.addEventListener('touchmove', vm.touchmove);
    document.addEventListener('touchend', vm.touchend);
  },
  methods:{
    mousedown(e){
      var vm = this
      var rect = vm.canvas.getBoundingClientRect();
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top;
      
      vm.isDrawing = true;
      vm.startX = x;
      vm.startY = y;
      vm.points.push({
        x: x,
        y: y
      });
    },
    mousemove(e){
      var vm = this
      var rect = vm.canvas.getBoundingClientRect();
      var x = e.clientX - rect.left;
      var y = e.clientY - rect.top;
      
      if (vm.isDrawing) {
        vm.context.beginPath();
        vm.context.moveTo(vm.startX, vm.startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 1;
        vm.context.lineCap = 'round';
        vm.context.strokeStyle = "rgba(0,0,0,1)";
        vm.context.stroke();
        
        vm.startX = x;
        vm.startY = y;  
        
        vm.points.push({
          x: x,
          y: y
        });
      }
    },
    mouseup(e){
      var vm = this
      vm.isDrawing = false;
      if (vm.points.length > 0) {
        localStorage['points'] = JSON.stringify(vm.points);    
      }
    },
    touchstart(e){
        var vm = this;

        var rect = vm.canvas.getBoundingClientRect();
        var x = e.touches[0].pageX - rect.left;
        var y = e.touches[0].pageY - rect.top;

        vm.isDrawing = true;
        vm.startX = x;
        vm.startY = y;
        vm.points.push({
            x: x,
            y: y
        });
    },
    touchmove(e){
        var vm = this;
        var rect = vm.canvas.getBoundingClientRect();
        var x = e.touches[0].pageX - rect.left;
        var y = e.touches[0].pageY - rect.top;

        if (vm.isDrawing) {
        vm.context.beginPath();
        vm.context.moveTo(vm.startX, vm.startY);
        vm.context.lineTo(x, y);
        vm.context.lineWidth = 1;
        vm.context.lineCap = 'round';
        vm.context.strokeStyle = "rgba(0,0,0,1)";
        vm.context.stroke();
        
        vm.startX = x;
        vm.startY = y;  
        
        vm.points.push({
          x: x,
          y: y
        });
      }
    },
    touchend(e){
        var vm = this;
        vm.isDrawing = false;
        if (vm.points.length > 0) {
            localStorage['points'] = JSON.stringify(vm.points);    
        }
    },
    resetCanvas(){
      var vm = this
      vm.canvas.width = vm.canvas.width;
      vm.points.length = 0; // reset points array
    },
    saveImage(){
      var vm = this
      var dataURL = vm.canvas.toDataURL();
      var img = vm.$refs.img;
      img.src = dataURL;
      vm.isActive= false;
    },
    sendData(){
      let config = {
        headers: {
          'Content-Type': 'application/json',
        }
      };
      var vm = this;
      vm.isActive = true;
      console.log(this.$props.serviceOrderId )
      // let url = "http://localhost:8000/firma2";
      let url = "https://simed.mx/firma2";
      let path = window.location.href;
      path = path.replace("firmar/", "firmar2/");
      let asd = {
        serviceOrderId:this.$props.serviceOrderId,
        image:vm.canvas.toDataURL(),
        _token: this.csrfToken
      };
      axios.post(url, asd,config)
      .then(function( response ){
        if(response.status == 200 || response.status == 201){
          vm.isActive = false;
          window.location.href = '/calendar';
          // document.location.href = path;
        }else{
          console.log(response);
          vm.isActive = false;
        }
      }.bind(this));
    },
  },
  created() {
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
  },
  };
</script>
