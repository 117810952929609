import Firma1 from './components/ExampleComponent3.vue';
import Firma2 from './components/ExampleComponent4.vue';

require('./bootstrap');
window.Vue = require('vue');
 Vue.component('example-component', require('./components/ExampleComponent.vue').default);
 Vue.component('firma-component', require('./components/FirmaComponent.vue').default);
 Vue.component('example-component2', require('./components/ExampleComponent2.vue').default);

 Vue.component('firma-component1', Firma1);
 Vue.component('firma-component2', Firma2);
 const app = new Vue({
   el: '#app',
 });